import React, { useEffect } from 'react';
import Accordion from './Accordion';
import useGenerateRandomColor from './useGenerateRandomColor';

const DateCard = ({ year, books }) => {
  const { color, generateColor } = useGenerateRandomColor();

  useEffect(() => {
    generateColor();
  }, []);
  return (
    <div className="date-card">
      <span
        className="top-bar"
        style={{
          backgroundColor: '#' + color,
        }}
      ></span>

      <div className="date-card__header flex justify-between mb-15">
        <p className="h4 fw-500">{year}</p>
        <button>
          <svg
            width="8"
            height="24"
            viewBox="0 0 8 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.11765 9.84708C3.83955 9.84708 3.56418 9.90277 3.30726 10.011C3.05033 10.1192 2.81689 10.2777 2.62024 10.4777C2.4236 10.6776 2.26762 10.9149 2.1612 11.1761C2.05477 11.4373 2 11.7173 2 12C2 12.2827 2.05477 12.5627 2.1612 12.8239C2.26762 13.0851 2.4236 13.3224 2.62024 13.5223C2.81689 13.7223 3.05033 13.8808 3.30726 13.989C3.56418 14.0972 3.83955 14.1529 4.11765 14.1529C4.67928 14.1528 5.21786 13.9258 5.61491 13.522C6.01196 13.1182 6.23494 12.5705 6.23481 11.9995C6.23469 11.4285 6.01145 10.881 5.61423 10.4773C5.217 10.0737 4.67832 9.84695 4.11669 9.84708H4.11765ZM4.11765 7.30388C4.39561 7.30375 4.67084 7.24796 4.92759 7.1397C5.18435 7.03144 5.41763 6.87282 5.61409 6.6729C5.81055 6.47298 5.96636 6.23568 6.07262 5.97455C6.17887 5.71341 6.2335 5.43356 6.23337 5.15096C6.23324 4.86837 6.17837 4.58856 6.07188 4.32752C5.96539 4.06649 5.80937 3.82933 5.61273 3.6296C5.41609 3.42986 5.18267 3.27146 4.92582 3.16343C4.66896 3.05541 4.39369 2.99987 4.11572 3C3.55434 3.00026 3.01606 3.22723 2.61928 3.63098C2.22251 4.03473 1.99975 4.58219 2 5.15292C2.00026 5.72365 2.22351 6.2709 2.62064 6.67428C3.01778 7.07767 3.55627 7.30414 4.11765 7.30388V7.30388ZM4.11765 16.6942C3.55601 16.6942 3.01738 16.921 2.62024 17.3247C2.22311 17.7285 2 18.2761 2 18.8471C2 19.4181 2.22311 19.9657 2.62024 20.3694C3.01738 20.7732 3.55601 21 4.11765 21C4.67928 21 5.21791 20.7732 5.61505 20.3694C6.01219 19.9657 6.23529 19.4181 6.23529 18.8471C6.23529 18.2761 6.01219 17.7285 5.61505 17.3247C5.21791 16.921 4.67928 16.6942 4.11765 16.6942V16.6942Z"
              fill="#707090"
            />
          </svg>
        </button>
      </div>

      <div className="date-card__body">
        <div className="accordions">
          {books?.map((item, index) => (
            <Accordion item={item} key={`year:${year}-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DateCard;
