import React, { useState } from 'react';

const Accordion = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={`accordion ${open ? 'open' : ''}`}>
      <button className="accordion__header " onClick={handleOpen}>
        <span className="text">{item?.title}</span>
      </button>
      <div className="accordion__body">
        <div className="content">
          <div>
            <div className="flex flex-wrap justify-between items-center mb-25 gap-1">
              <small className="bg-green text-white py-5 px-15 rounded-2">
                {item?.edition_count} Editions
              </small>
              <small className="text-muted">
                First Published: {item?.first_publish_year}
              </small>
            </div>
            <div className="flex gap-2 text-muted">
              <p className="flex gap-1">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.06225 7.74776L10.7706 12.7715C10.8402 12.812 10.9192 12.8333 10.9997 12.8333C11.0803 12.8333 11.1593 12.812 11.2289 12.7715L19.9372 7.74776C20.0069 7.70753 20.0647 7.64968 20.1049 7.58008C20.2316 7.36082 20.1565 7.0804 19.9372 6.95374L11.2289 1.93445C11.0867 1.85388 10.9127 1.85388 10.7706 1.93445L2.06225 6.95374C1.99259 6.99396 1.93479 7.05181 1.89457 7.12141C1.7679 7.34068 1.84298 7.62109 2.06225 7.74776ZM10.9997 2.86096L18.7905 7.3512L10.9997 11.8459L3.20897 7.3512L10.9997 2.86096ZM19.4771 14.2325L10.9997 19.1774L2.52231 14.2325C2.30378 14.1049 2.02325 14.1786 1.89569 14.3972C1.76812 14.6157 1.84186 14.8962 2.0604 15.0238L10.7687 20.104C10.8388 20.1451 10.9185 20.1667 10.9997 20.1666C11.0809 20.1667 11.1607 20.1451 11.2306 20.104L19.939 15.0238C20.1575 14.8962 20.2313 14.6157 20.1037 14.3972C19.9761 14.1786 19.6956 14.1049 19.4771 14.2325ZM19.4771 10.5658L10.9997 15.5108L2.52231 10.5658C2.30378 10.4382 2.02325 10.512 1.89569 10.7305C1.76812 10.949 1.84186 11.2296 2.0604 11.3571L10.7687 16.4373C10.8388 16.4784 10.9185 16.5001 10.9997 16.5C11.0809 16.5001 11.1607 16.4784 11.2306 16.4373L19.939 11.3571C20.1575 11.2296 20.2313 10.949 20.1037 10.7305C19.9761 10.512 19.6956 10.4382 19.4771 10.5658Z"
                    fill="currentColor"
                  />
                </svg>

                <small>{item?.number_of_pages_median} pages</small>
              </p>
              <p className="flex gap-1">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.7335 3.80286C15.8899 1.87764 12.9095 3.3955 12.0005 4.02821C11.0915 3.3955 8.10918 1.87548 2.26743 3.80286C2.16789 3.83562 2.08044 3.90389 2.01815 3.99744C1.95586 4.09099 1.92208 4.20479 1.92188 4.32181V20.2414C1.92188 20.3255 1.93922 20.4084 1.97254 20.4837C2.00586 20.5589 2.05424 20.6243 2.11385 20.6748C2.17345 20.7252 2.24265 20.7593 2.31596 20.7744C2.38926 20.7894 2.46466 20.785 2.53619 20.7614C9.01337 18.6282 11.6664 20.9944 11.6904 21.016C11.7132 21.0348 11.7377 21.0508 11.7634 21.0637C11.7749 21.0713 11.7845 21.0832 11.796 21.0897H11.8027L11.8258 21.1005C11.8813 21.1261 11.9405 21.1397 12.0005 21.1406C12.0173 21.1377 12.034 21.1337 12.0504 21.1287C12.0916 21.1257 12.1322 21.1166 12.1713 21.1016L12.1925 21.1027C12.2047 21.0948 12.2166 21.0861 12.228 21.0767L12.2385 21.068C12.2624 21.056 12.2852 21.0415 12.3067 21.0247C12.3336 21.0009 14.9866 18.6336 21.4638 20.7668C21.5353 20.7904 21.6107 20.7948 21.684 20.7798C21.7573 20.7647 21.8265 20.7306 21.8862 20.6802C21.9458 20.6297 21.9941 20.5643 22.0275 20.4891C22.0608 20.4139 22.0781 20.3309 22.0781 20.2468V4.32181C22.078 4.20492 22.0443 4.09123 21.9822 3.9977C21.9201 3.90417 21.8329 3.83582 21.7335 3.80286V3.80286ZM2.88174 19.5285V4.73134C8.2234 3.06614 10.8621 4.51466 11.5205 4.96969V19.6585C10.291 18.9998 7.56493 18.1309 2.88174 19.5285ZM21.1192 19.5285C16.4341 18.1298 13.709 18.9987 12.4804 19.6574V4.96969C13.1379 4.51249 15.7737 3.06722 21.1192 4.73134V19.5285Z"
                    fill="currentColor"
                  />
                </svg>
                <small>32 hours read times</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
