import React, { useEffect, useRef, useState } from 'react';
import DateCard from '../components/DateCard';
import Spinner from '../components/Spinner';
import axios from 'axios';
import Empty from '../components/Empty';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [searchResultIsEmpty, setSearchResultIsEmpty] = useState(false);
  const [searchInput, setSearchInput] = useState('OL26320A');
  const [docs, setDocs] = useState([]);
  const [publishYears, setPublishYears] = useState([]);
  const [publishYearsWithBooks, setPublishYearsWithBooks] = useState({});

  const scrollArea = useRef(null);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
  };

  function sortArrayCorrectly(a, b) {
    return a - b;
  }
  function checkData(data) {
    if (data.length <= 0) {
      setSearchResultIsEmpty(true);
    } else {
      setSearchResultIsEmpty(false);
    }
  }

  const createKeyValuePublishYears = () => {
    let dateKeys = {};
    publishYears.forEach((year, index) => {
      let tempArr = [];
      docs.forEach((doc, i) => {
        if (doc.publish_year.includes(year)) {
          tempArr.push(doc);
          dateKeys[year] = tempArr;
        }
      });
    });
    setPublishYearsWithBooks(dateKeys);
  };

  async function getAuthorData() {
    try {
      const response = await axios.get(
        `http://openlibrary.org/search.json?author=${searchInput}`,
      );
      await checkData(response.data.docs);
      await setLoading(false);
      await setDocs(response.data.docs);
      await concatPublishYears();
    } catch (err) {
      await setLoading(false);
      console.log(err);
    }
  }

  const concatPublishYears = () => {
    let tempArray = [];
    docs?.forEach((item, index) => {
      tempArray = tempArray.concat(item.publish_year);
      tempArray = [...new Set(tempArray)];
      tempArray.sort(sortArrayCorrectly);
      setPublishYears(tempArray);
    });
  };

  useEffect(() => {
    concatPublishYears();
  }, [docs]);

  useEffect(() => {
    createKeyValuePublishYears();
  }, [publishYears]);

  useEffect(() => {
    if (!searchResultIsEmpty) {
      scrollArea.current.addEventListener('wheel', (event) => {
        event.preventDefault();
        scrollArea.current.scrollBy({
          left: event.deltaY < 0 ? -30 : 30,
        });
      });
    }
  }, [searchResultIsEmpty]);

  return (
    <section className="container section-padding">
      <div className="flex items-center flex-wrap gap-3 mb-40">
        <p className="h2">Lorem Board</p>
        <form
          className="flex items-end flex-wrap gap-1"
          onSubmit={handleSubmit}
        >
          <span className="h5">books of</span>
          <input
            type="search"
            className="form-input"
            value={searchInput}
            placeholder="Find Author"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
          <button
            type="submit"
            className="btn btn-dark"
            disabled={!searchInput}
            onClick={() => getAuthorData()}
          >
            Submit
          </button>
          {loading && <Spinner />}
        </form>
      </div>
      <div className="flex items-start gap-2">
        {docs.length <= 0 && !searchResultIsEmpty && <Empty />}
        {!searchResultIsEmpty ? (
          publishYearsWithBooks !== null && (
            <div className="blur-wrap blur-wrap__horizontal w-100">
              <div
                className="date-cards-wrapper flex items-start gap-2 overflow-x-auto w-100"
                ref={scrollArea}
              >
                {Object.keys(publishYearsWithBooks).map((key, index) => (
                  <DateCard
                    year={key}
                    books={publishYearsWithBooks[key]}
                    key={`'year-${key}`}
                  />
                ))}
              </div>
            </div>
          )
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/no-board.jpg`}
            alt=""
            className="w-100"
          />
        )}
      </div>
    </section>
  );
};

export default Home;
